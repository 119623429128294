import { useCallback, useMemo } from "react";
import { Permission } from "../permissions";
import { Role } from "../models/user/Role";
import { RoleUtils } from "../utils/role.utils";
import { useOidcIdToken } from "@axa-fr/react-oidc";

interface PermissionManager {
    role: Role;
    hasPermission: (permission: Permission) => boolean;
}

export function usePermissions(): PermissionManager {
    const { idToken } = useOidcIdToken();
    const isLoggedIn = idToken !== undefined;

    const role = useMemo(
        () => RoleUtils.getRoleFromIdToken(idToken),
        [idToken],
    );

    const hasPermission = useCallback(
        (permission: Permission) => {
            if (!isLoggedIn) {
                return false;
            }
            return permission.roles.findIndex(f => f === role) !== -1;
        },
        [isLoggedIn, role],
    );

    return useMemo(
        () => ({
            role,
            hasPermission,
        }),
        [hasPermission, role],
    );
}
