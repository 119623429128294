import { SchemaDescription, SchemaFieldDescription, SchemaObjectDescription } from "yup/lib/schema";
import { AnyObject } from "yup/lib/types";
import { ObjectSchema } from "yup";

function isSchemaDescription(schema: SchemaFieldDescription): schema is SchemaDescription {
    return !!schema && "tests" in schema;
}

function isSchemaObjectDescription(schema: SchemaFieldDescription): schema is SchemaObjectDescription {
    return !!schema && "fields" in schema;
}

function getField(desc: SchemaObjectDescription, key: string): SchemaFieldDescription | undefined {
    let keyToSearch = key;
    let remainder = "";

    const splitIndex = key.indexOf(".");
    if (splitIndex >= 0) {
        keyToSearch = key.split(".")[0];
        remainder = key.substring(splitIndex + 1);
    }

    const field = desc.fields[keyToSearch];
    if (remainder !== "" && isSchemaObjectDescription(field)) {
        return getField(field, remainder);
    }

    return field;
}

export function isRequired(validationSchema: ObjectSchema<AnyObject>, key: string): boolean {
    const field = getField(validationSchema.describe(), key);
    if (field === undefined) {
        return false;
    }
    if (isSchemaDescription(field)) {
        return (
            field.tests.filter(f => f.name === "required").length > 0
        );
    }
    return false;
}