import * as datagrid from "@mui/x-data-grid/locales";
import * as datefns from "date-fns/locale";
import * as material from "@mui/material/locale";
import {
    KeyPrefix,
    Namespace,
    UseTranslationResponse,
    useTranslation,
} from "react-i18next";
import { Localization as DataGridLocalization } from "@mui/x-data-grid/utils/getGridLocalization";
import { Localization as MaterialLocalization } from "@mui/material/locale";

export type SupportedLocales = "nl" | "en";

export const DefaultLocale: SupportedLocales = "nl";

export interface ApplicationLocale {
    material: MaterialLocalization;
    datagrid: DataGridLocalization;
    datefns: Locale;
    libphone: string;
}

export const Locales: Record<string, ApplicationLocale> = {
    en: {
        material: material.enUS,
        datagrid: datagrid.enUS,
        datefns: datefns.enUS,
        libphone: "US"
    },
    nl: {
        material: material.nlNL,
        datagrid: datagrid.nlNL,
        datefns: datefns.nl,
        libphone: "NL"
    },
};

type UseLocalesResponse<
    N extends Namespace<string>,
    TKPrefix = undefined,
> = UseTranslationResponse<N, TKPrefix> & {
    libraries: ApplicationLocale;
};

export function useLocales<
    N extends Namespace<string> = "translation",
    TKPrefix extends KeyPrefix<N> = undefined,
>(): UseLocalesResponse<N, TKPrefix> {
    const useTsResponse = useTranslation<N, TKPrefix>();
    const response = useTsResponse as UseLocalesResponse<N, TKPrefix>;
    response.libraries = Locales[useTsResponse.i18n.language];
    return response;
}
