import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { Fragment, useMemo, useState } from "react";
import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  Logout,
} from "@mui/icons-material";
import { useOidc, useOidcIdToken } from "@axa-fr/react-oidc";
import { PagesFinder } from "../../pages";
import { Link as ReactRouterLink } from "react-router-dom";
import { RouterLink } from "./RouterLink";
import { useTranslation } from "react-i18next";

export function DrawerLayout({ children }: React.PropsWithChildren) {
  const { t } = useTranslation();
  const { isAuthenticated, logout } = useOidc();
  const { idToken } = useOidcIdToken();

  const [collapsed, setCollapsed] = useState(false);

  const pages = useMemo(() => {
    if (isAuthenticated) {
      return PagesFinder.getPages(idToken).filter(f => f.isNavigation);
    } else {
      return [];
    }
  }, [isAuthenticated, idToken]);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          zIndex: theme => theme.zIndex.drawer + 1,
          backgroundColor: "#D94080",
        }}
      >
        <Toolbar title={t("app")}>
          <Typography variant="h6" noWrap component="div">
            <Link
              component={ReactRouterLink}
              to="/"
              color="inherit"
              underline="none"
            >
              {t("app")}
            </Link>
          </Typography>
        </Toolbar>
      </AppBar>
      <Box display="flex" width="100%">
        <Drawer
          variant="permanent"
          sx={{
            flex: 0,
            ["& .MuiDrawer-paper"]: {
              position: "relative",
              boxSizing: "border-box",
            },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: "auto" }}>
            <Box display="flex" justifyContent="center" paddingY={1}>
              <IconButton onClick={() => setCollapsed(old => !old)}>
                {collapsed ? (
                  <KeyboardDoubleArrowRight />
                ) : (
                  <KeyboardDoubleArrowLeft />
                )}
              </IconButton>
            </Box>

            <Divider />

            {isAuthenticated && (
              <>
                <List>
                  {pages.map((page, idx) => {
                    const Icon = page.icon;
                    return (
                      <Fragment key={idx}>
                        {page.navigationDivider ? <Divider /> : ""}
                        {Icon !== undefined ? (
                          <RouterLink
                            to={`/app/${page.path}`}
                            text={t(page.name)}
                            icon={<Icon />}
                            collapsed={collapsed}
                          />
                        ) : (
                          <RouterLink
                            to={`/app/${page.path}`}
                            text={t(page.name)}
                            collapsed={collapsed}
                          />
                        )}
                      </Fragment>
                    );
                  })}
                </List>

                <Divider />

                <List>
                  <ListItemButton onClick={() => logout("/")}>
                    <ListItemIcon sx={{ minWidth: collapsed ? 0 : undefined }}>
                      <Logout />
                    </ListItemIcon>
                    {!collapsed && (
                      <ListItemText primary={t("common.logout")} />
                    )}
                  </ListItemButton>
                </List>
              </>
            )}
          </Box>
        </Drawer>
        <Box
          component="main"
          flexGrow={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 3,
          }}
        >
          <Toolbar />
          {children}
        </Box>
      </Box>
    </>
  );
}
