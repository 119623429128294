import "yup-phone";
import * as yup from "yup";
import { CustomerGender } from "../../models/customer/Customer";
import { FormTranslator } from "../../hooks/useFormTranslator";
import { useLocales } from "../../hooks/useLocales";
import { useMemo } from "react";
import { useYup } from "../../hooks/useYup";

export function useValidationSchema(translator: FormTranslator) {
    const { t, libraries } = useLocales();

    const validationSchema = useMemo(() => {
        return yup.object().shape({
            name: yup.string().required(),
            gender: yup.mixed().oneOf(Object.values(CustomerGender)),
            dateOfBirth: yup.date().required(),
            address: yup.string(),
            postal: yup.string(),
            residence: yup.string(),
            email: yup.string().email(),
            phoneNumber: yup.lazy(value => (value ?? "").length > 0 ? yup.string().phone(libraries.libphone, false, t("errors.phone", { name: translator.getField("phoneNumber").getName() })) : yup.string()),
            note: yup.string(),
            gp: yup.object({
                name: yup.string(),
                phoneNumber: yup.lazy(value => (value ?? "").length > 0 ? yup.string().phone(libraries.libphone, false, t("errors.phone", { name: translator.getField("gp.phoneNumber").getName() })) : yup.string())
            }).required(),
            diseases: yup.object({
                diabetes: yup.boolean().required(),
                diabetesAdd: yup.string(),
                heartVascular: yup.boolean().required(),
                heartVascularAdd: yup.string(),
                antiCoagulants: yup.boolean().required(),
                antiCoagulantsAdd: yup.string(),
                rheumatism: yup.boolean().required(),
                allergy: yup.boolean().required(),
                allergyAdd: yup.string(),
                elasticStockings: yup.boolean().required(),
                feetType: yup.string(),
                orthopedicAbnormalities: yup.string(),
                archSupports: yup.boolean().required(),
                customizedReadyToWearShoes: yup.boolean().required(),
                orthopedicShoes: yup.boolean().required(),
                skinCondition: yup.string(),
                skinAffections: yup.string(),
                nailCondition: yup.string(),
                nailAffections: yup.string(),
            })
        });
    }, [libraries.libphone, t, translator]);

    return useYup(validationSchema, translator);
}