import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from "@mui/material";
import { FormValues, formikEventWrapper } from "./utils";
import { FormTranslator } from "../../../hooks/useFormTranslator";
import { FormikProps } from "formik";

type Key = number | string;

export interface Option {
  value: Key;
  text: string;
}

type FormikRadioProps<T extends FormValues> = {
  formik: FormikProps<T>;
  field: string;
  translator: FormTranslator;
  helperText?: string;
  options: Option[];
} & Omit<RadioGroupProps, "id" | "name" | "label" | "value" | "error">;

export function FormikRadio<T extends FormValues>({
  formik,
  field,
  translator,
  helperText,
  options,
  onChange,
  onBlur,
  ...props
}: FormikRadioProps<T>) {
  const label = translator.getField(field).getName();
  const meta = formik.getFieldMeta<Key | undefined>(field);

  const errorText = meta.error;
  const hasError = meta.touched && errorText !== undefined;
  const helpText = hasError ? errorText : helperText;

  const onChangeInternal = formikEventWrapper(formik.handleChange, onChange);
  const onBlurInternal = formikEventWrapper(formik.handleBlur, onBlur);

  return (
    <FormGroup>
      <FormControl>
        <FormLabel id={field} required>
          {label}
        </FormLabel>
        <RadioGroup
          {...props}
          name={field}
          value={meta.value ?? options[0].value}
          onChange={onChangeInternal}
          onBlur={onBlurInternal}
        >
          {options.map(option => (
            <FormControlLabel
              key={`${field}_option_${option.value}`}
              value={option.value}
              control={<Radio />}
              label={option.text}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <FormHelperText error={hasError}>{helpText}</FormHelperText>
    </FormGroup>
  );
}
