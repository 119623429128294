import {
  Customer,
  CustomerAdd,
  CustomerEdit,
  CustomerOverview,
} from "../models/customer/Customer";
import {
  CustomerNoteCreate,
  CustomerNoteEdit,
} from "../models/customer/CustomerNote";
import { Pagination, SortDirection } from "../models/pagination/Pagination";
import {
  UsePaginationServiceResult,
  usePaginationService,
} from "./PaginationService";
import { useCallback, useMemo } from "react";
import useSWR, { SWRResponse } from "swr";
import { CustomerAffectionCreate } from "../models/customer/CustomerAffection";
import { Error } from "../models/error/Error";
import { getUnixTime } from "date-fns";
import { useFetch } from "../hooks/useFetch";
import { useNavigate } from "react-router-dom";

export type AddCustomerListCallback = (body: CustomerAdd) => Promise<void>;
export type EditCustomerViewCallback = (body: CustomerEdit) => Promise<void>;
export type DeleteCustomerViewCallback = () => Promise<void>;
export type AddCustomerAffectionViewCallback = (
  body: CustomerAffectionCreate,
) => Promise<void>;
export type DeleteCustomerAffectionViewCallback = (id: number) => Promise<void>;
export type AddCustomerNoteViewCallback = (
  body: CustomerNoteCreate,
) => Promise<void>;
export type EditCustomerNoteViewCallback = (
  body: CustomerNoteEdit,
) => Promise<void>;
export type DeleteCustomerNoteViewCallback = (id: number) => Promise<void>;
export type UploadCustomerNoteImageCallback = (customerNoteId: number, files: File[]) => Promise<void>;

interface CustomerServiceListResult {
  customersResponse: UsePaginationServiceResult<CustomerOverview, Error>;
  addCustomer: AddCustomerListCallback;
}

interface CustomerServiceViewResult {
  customerResponse: SWRResponse<Customer | undefined, Error>;
  editCustomer: EditCustomerViewCallback;
  deleteCustomer: DeleteCustomerViewCallback;
  addCustomerAffection: AddCustomerAffectionViewCallback;
  deleteCustomerAffection: DeleteCustomerAffectionViewCallback;
  addCustomerNote: AddCustomerNoteViewCallback;
  editCustomerNote: EditCustomerNoteViewCallback;
  deleteCustomerNote: DeleteCustomerNoteViewCallback;
  uploadCustomerNoteImage: UploadCustomerNoteImageCallback;
}

export const CustomerListPath = "customers";
export const CustomerCreatePath = "customers";
export const CustomerViewPath = (id: number) => `customers/${id}`;
export const CustomerEditPath = (id: number) => `customers/${id}`;
export const CustomerDeletePath = (id: number) => `customers/${id}`;
export const CustomerAffectionAddPath = (customerId: number) =>
  `customers/${customerId}/affections`;
export const CustomerAffectionDeletePath = (customerId: number, id: number) =>
  `customers/${customerId}/affections/${id}`;
export const CustomerNoteAddPath = (customerId: number) =>
  `customers/${customerId}/notes`;
export const CustomerNoteEditPath = (customerId: number, id: number) =>
  `customers/${customerId}/notes/${id}`;
export const CustomerNoteDeletePath = (customerId: number, id: number) =>
  `customers/${customerId}/notes/${id}`;
export const CustomerNoteImageUploadPath = (customerId: number) =>
  `customers/${customerId}/notes/images`;

export const DefaultCustomerGroupId = -1;

const defaultPagination: Pagination = {
  page: 1,
  itemsPerPage: 100,
  itemsPerPageOptions: [10, 20, 50, 100],
  sort: "name",
  sortDirection: SortDirection.Ascending,
};

export function useCustomerListService(): CustomerServiceListResult {
  const fetch = useFetch();
  const navigation = useNavigate();
  const customersResponse = usePaginationService<CustomerOverview, Error>(
    defaultPagination,
    CustomerListPath,
  );

  const addCustomer = useCallback<AddCustomerListCallback>(
    async body => {
      const result = await fetch.post(CustomerCreatePath, {
        ...body,
        dateOfBirth: getUnixTime(body.dateOfBirth),
      }).then(resp => resp.json());
      await customersResponse.response.mutate();
      navigation(`/app/customers/${result.id}`);
    },
    [fetch, customersResponse.response, navigation],
  );

  return useMemo(
    () => ({
      customersResponse,
      addCustomer,
    }),
    [customersResponse, addCustomer],
  );
}

export function useCustomerViewService(
  customerId: number,
): CustomerServiceViewResult {
  const fetch = useFetch();
  const customerResponse = useSWR<Customer | undefined, Error>(
    CustomerViewPath(customerId),
  );

  const editCustomer = useCallback<EditCustomerViewCallback>(
    async body => {
      await customerResponse.mutate(async () => {
        return await fetch.put(CustomerEditPath(customerId), {
          ...body,
          dateOfBirth: getUnixTime(body.dateOfBirth),
        }).then(resp => resp.json());
      });
    },
    [customerResponse, fetch, customerId],
  );

  const deleteCustomer = useCallback<DeleteCustomerViewCallback>(async () => {
    await fetch.delete(CustomerDeletePath(customerId));
  }, [fetch, customerId]);

  const addCustomerAffection = useCallback<AddCustomerAffectionViewCallback>(
    async body => {
      await customerResponse.mutate(async customer => {
        if (customer) {
          const newAffection = await fetch.post(
            CustomerAffectionAddPath(customerId),
            body,
          ).then(resp => resp.json());
          return {
            ...customer,
            affections: customer.affections.concat([newAffection]),
          };
        }
        return customer;
      });
    },
    [customerResponse, fetch, customerId],
  );

  const deleteCustomerAffection =
    useCallback<DeleteCustomerAffectionViewCallback>(
      async id => {
        await customerResponse.mutate(async customer => {
          if (customer) {
            await fetch.delete(CustomerAffectionDeletePath(customerId, id));
            return {
              ...customer,
              affections: customer.affections.filter(f => f.id !== id),
            };
          }
          return customer;
        });
      },
      [customerId, customerResponse, fetch],
    );

  const addCustomerNote = useCallback<AddCustomerNoteViewCallback>(
    async body => {
      await customerResponse.mutate(async customer => {
        if (customer) {
          const newNote = await fetch.post(
            CustomerNoteAddPath(customerId),
            body,
          ).then(resp => resp.json());
          return {
            ...customer,
            notes: customer.notes.concat([newNote]),
          };
        }
        return customer;
      });
    },
    [customerResponse, fetch, customerId],
  );

  const editCustomerNote = useCallback<EditCustomerNoteViewCallback>(
    async body => {
      await customerResponse.mutate(async customer => {
        if (customer) {
          const newNote = await fetch.post(
            CustomerNoteEditPath(customerId, body.id),
            {
              note: body.note,
            },
          ).then(resp => resp.json());
          return {
            ...customer,
            notes: customer.notes
              .filter(f => f.id !== body.id)
              .concat([newNote]),
          };
        }
        return customer;
      });
    },
    [customerResponse, fetch, customerId],
  );

  const deleteCustomerNote = useCallback<DeleteCustomerNoteViewCallback>(
    async id => {
      await customerResponse.mutate(async customer => {
        if (customer) {
          await fetch.delete(CustomerNoteDeletePath(customerId, id));
          return {
            ...customer,
            notes: customer.notes.filter(f => f.id !== id),
          };
        }
        return customer;
      });
    },
    [customerId, customerResponse, fetch],
  );

  const uploadCustomerNoteImage = useCallback<UploadCustomerNoteImageCallback>(async (customerNoteId, files) => {
    await customerResponse.mutate(async customer => {
      if (customer) {
        const formData = new FormData();
        formData.append(
            "customerNoteId",
            customerNoteId.toString(),
        );
        for (const file of files) {
          formData.append("files", file);
        }
        await fetch.postForm(CustomerNoteImageUploadPath(customer.id), formData, true);
      }
      return customer;
    });
    await customerResponse.mutate();
  }, [customerResponse, fetch]);

  return useMemo(
    () => ({
      customerResponse,
      editCustomer,
      deleteCustomer,
      addCustomerAffection,
      deleteCustomerAffection,
      addCustomerNote,
      editCustomerNote,
      deleteCustomerNote,
      uploadCustomerNoteImage
    }),
    [
      customerResponse,
      editCustomer,
      deleteCustomer,
      addCustomerAffection,
      deleteCustomerAffection,
      addCustomerNote,
      editCustomerNote,
      deleteCustomerNote,
      uploadCustomerNoteImage
    ],
  );
}
