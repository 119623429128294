import { ComponentProps, useEffect } from "react";
import {
    GridActionsCell,
    GridActionsColDef,
    GridValidRowModel,
    useGridApiContext,
} from "@mui/x-data-grid";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const hasActions = (colDef: any): colDef is GridActionsColDef =>
    typeof colDef.getActions === "function";

export function createActionsColumn<T extends GridValidRowModel>(
    actions: GridActionsColDef<T>["getActions"],
): GridActionsColDef<T> {
    return {
        field: "actions",
        type: "actions",
        align: "left",
        disableColumnMenu: true,
        disableExport: true,
        disableReorder: true,
        getActions: actions,
        renderCell: params => <CustomColumn {...params} />,
    };
}

function CustomColumn(props: ComponentProps<typeof GridActionsCell>) {
    const apiRef = useGridApiContext();

    if (!hasActions(props.colDef)) {
        throw new Error(
            "MUI: Missing the `getActions` property in the `GridColDef`.",
        );
    }
    const options = props.colDef.getActions(
        apiRef.current.getRowParams(props.id),
    );
    const iconButtons = options.filter(option => !option.props.showInMenu);
    const menuButtons = options.filter(option => option.props.showInMenu);
    const numberOfButtons = iconButtons.length + (menuButtons.length ? 1 : 0);

    useEffect(() => {
        apiRef.current.setColumnWidth(props.field, numberOfButtons * 50);
    }, [apiRef, numberOfButtons, props.field]);

    return <GridActionsCell {...props} />;
}
