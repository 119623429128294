import { Box, Grid } from "@mui/material";
import { CustomerViewErrorRedirect, useCustomerIdFilter } from "./filter";
import { BasicInfo } from "./Blocks/BasicInfo";
import { CustomerGender } from "../../../models/customer/Customer";
import { DiseaseInfo } from "./Blocks/DiseaseInfo";
import { FeetInfo } from "./Blocks/FeetInfo";
import { GpInfo } from "./Blocks/GpInfo";
import { Helmet } from "react-helmet-async";
import { NoteInfo } from "./Blocks/NoteInfo";
import { NotesInfo } from "./Blocks/NotesInfo";
import { useCustomerViewService } from "../../../service/CustomerService";
import { useFormTranslator } from "../../../hooks/useFormTranslator";
import { useTranslation } from "react-i18next";

interface CustomerViewContentProps {
  customerId: number;
}

function CustomerViewContent({ customerId }: CustomerViewContentProps) {
  const { t } = useTranslation();
  const customerTranslator = useFormTranslator("customer");

  const {
    customerResponse,
    editCustomer,
    deleteCustomer,
    addCustomerAffection,
    deleteCustomerAffection,
    addCustomerNote,
    editCustomerNote,
    deleteCustomerNote,
    uploadCustomerNoteImage
  } = useCustomerViewService(customerId);

  return (
    <>
      <Helmet
        title={`${t("pages.customers.view.header")} - ${customerTranslator
          .getField("gender")
          .getEnum(customerResponse.data?.gender ?? CustomerGender.Mr)} ${customerResponse.data?.name
          }`}
      />

      <Grid container spacing={3}>
        <Grid item lg={8} xs={12}>
          <Box display="flex" flexDirection="column" gap={3}>
            <BasicInfo
              customer={customerResponse.data}
              translator={customerTranslator}
              editCustomer={editCustomer}
              deleteCustomer={deleteCustomer}
            />

            <FeetInfo
              customer={customerResponse.data}
              addAffection={addCustomerAffection}
              deleteEffection={deleteCustomerAffection}
            />

            <NotesInfo
              customer={customerResponse.data}
              addNote={addCustomerNote}
              editNote={editCustomerNote}
              deleteNote={deleteCustomerNote}
              uploadNoteImage={uploadCustomerNoteImage}
            />
          </Box>
        </Grid>
        <Grid item lg={4} xs={12}>
          <Box display="flex" flexDirection="column" gap={3}>
            <GpInfo
              customer={customerResponse.data}
              translator={customerTranslator}
            />

            <DiseaseInfo
              customer={customerResponse.data}
              translator={customerTranslator}
            />

            <NoteInfo
              customer={customerResponse.data}
              translator={customerTranslator}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export function CustomerViewPage() {
  const { customerId } = useCustomerIdFilter();

  if (customerId === undefined) {
    return <CustomerViewErrorRedirect />;
  } else {
    return <CustomerViewContent customerId={customerId} />;
  }
}
