import {
  DesktopDatePicker,
  DesktopDatePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FormTranslator } from "../../../hooks/useFormTranslator";
import { FormValues } from "./utils";
import { FormikProps } from "formik";
import { TextField } from "@mui/material";
import { useLocales } from "../../../hooks/useLocales";

export type FormikDateInputProps<T extends FormValues> = {
  formik: FormikProps<T>;
  field: string;
  translator: FormTranslator;
  helperText?: string;
  onChange?: (date: Date | null, keyboardInputValue?: string) => void;
  onClose?: () => void;
  valueModifier?: (date: Date) => Date;
} & Omit<
  DesktopDatePickerProps<Date, Date>,
  "label" | "value" | "onChange" | "onClose" | "renderInput"
>;

export function FormikDateInput<T extends FormValues>({
  formik,
  field,
  translator,
  helperText,
  onChange,
  onClose,
  valueModifier = date => date,
  ...props
}: FormikDateInputProps<T>) {
  const { libraries } = useLocales();

  const translatorField = translator.getField(field);
  const label = translatorField.getName();
  const meta = formik.getFieldMeta<Date | undefined>(field);

  const errorText = meta.error;
  const hasError = meta.touched && errorText !== undefined;
  const helpText = hasError ? errorText : helperText;

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={libraries.datefns}
    >
      <DesktopDatePicker
        label={label}
        value={meta.value ?? new Date()}
        onChange={(value, input) => {
          if (value) {
            formik.setFieldValue(field, valueModifier(value));
          } else {
            formik.setFieldValue(field, undefined);
          }
          if (onChange) {
            onChange(value, input);
          }
        }}
        onClose={() => {
          formik.setFieldTouched(field);
          if (onClose) {
            onClose();
          }
        }}
        renderInput={params => (
          <TextField
            id={field}
            name={field}
            error={hasError}
            helperText={helpText}
            {...params}
          />
        )}
        {...props}
      />
    </LocalizationProvider>
  );
}
