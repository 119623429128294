import { ButtonGroup, Grid } from "@mui/material";
import {
  ServiceResponse,
  useResponseCombiner,
} from "../../../hooks/useServiceResponse";
import { AddCustomerDialog } from "./AddCustomerDialog";
import { ApplicationPagingDataGrid } from "../../../components/Controls/DataGrid/Paging";
import { CustomerOverview } from "../../../models/customer/Customer";
import { ErrorsCard } from "../../../components/Layout/ErrorsCard";
import { GridColDef } from "@mui/x-data-grid";
import { Permissions } from "../../../permissions";
import { useCustomerListService } from "../../../service/CustomerService";
import { useFormTranslator } from "../../../hooks/useFormTranslator";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { usePermissions } from "../../../hooks/usePermissions";

export function CustomerIndexPage() {
  const navigate = useNavigate();
  const translator = useFormTranslator("customer");
  const { hasPermission } = usePermissions();

  const { customersResponse, addCustomer } = useCustomerListService();

  const responseData = useMemo(
    () => ({
      Customers: customersResponse.response,
    }),
    [customersResponse.response],
  );
  const response = useResponseCombiner(responseData);

  const columns = useMemo<GridColDef<CustomerOverview>[]>(
    () => [
      {
        field: "name",
        headerName: translator.getField("name").getName(),
        hideable: false,
        flex: 1,
        valueGetter: params =>
          `${translator.getField("gender").getEnum(params.row.gender)} ${
            params.row.name
          }`,
      },
      {
        field: "address",
        headerName: translator.getField("address").getName(),
        hideable: false,
        flex: 1,
        valueGetter: params => {
          if (params.row.address) {
            return `${params.row.address} - ${params.row.postal ?? ""} ${
              params.row.residence ?? ""
            }`;
          } else {
            return `${params.row.postal ?? ""} ${params.row.residence ?? ""}`;
          }
        },
      },
    ],
    [translator],
  );

  const canEdit = hasPermission(Permissions.CustomersManage);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {canEdit && (
          <ButtonGroup>
            <AddCustomerDialog addCustomer={addCustomer} />
          </ButtonGroup>
        )}
      </Grid>
      <Grid item xs={12}>
        <ServiceResponse
          response={response}
          options={{ errorComponent: ErrorsCard }}
        >
          {response.data.Customers && (
            <ApplicationPagingDataGrid
              pagination={response.data.Customers}
              paginationControls={customersResponse.paginationControls}
              columns={columns}
              getRowId={row => row.id}
              disableColumnMenu
              disableSelectionOnClick
              autoHeight
              onCellClick={params =>
                navigate(`/app/customers/${params.row.id}`)
              }
            />
          )}
        </ServiceResponse>
      </Grid>
    </Grid>
  );
}
