import { Box, Link } from "@mui/material";
import { GridRenderCellParams, GridValidRowModel } from "@mui/x-data-grid";
import { useState } from "react";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ExpandableCellProps<T extends GridValidRowModel> extends GridRenderCellParams<any, T> {
    maxLength?: number;
    defaultExpanded?: boolean;
}

export function ExpandableCell<T extends GridValidRowModel>({ value, maxLength = 200, defaultExpanded = false }: ExpandableCellProps<T>) {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(defaultExpanded);

    return (
        <Box>
            {expanded ? value : value.slice(0, maxLength) + "... "}
            {value.length > maxLength && (
                <Link
                    type="button"
                    component="button"
                    sx={{ fontSize: "inherit" }}
                    onClick={() => setExpanded(!expanded)}
                >
                    {expanded ? t("pages.customers.view.texts.note_view_less") : t("pages.customers.view.texts.note_view_more")}
                </Link>
            )}
        </Box>
    );
}