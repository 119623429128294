import {
  AddCustomerNoteViewCallback,
  DeleteCustomerNoteViewCallback,
  EditCustomerNoteViewCallback,
  UploadCustomerNoteImageCallback,
} from "../../../../../service/CustomerService";
import { Card, CardContent, CardHeader } from "@mui/material";
import { GridColDef, gridClasses } from "@mui/x-data-grid";
import { fromUnixTime, isEqual } from "date-fns";
import { AddNoteDialog } from "./AddNoteDialog";
import { ApplicationStandardDataGrid } from "../../../../../components/Controls/DataGrid/Standard";
import { ConfirmButton } from "../../../../../components/Controls/ConfirmButton";
import { Customer } from "../../../../../models/customer/Customer";
import { CustomerNote } from "../../../../../models/customer/CustomerNote";
import { Delete } from "@mui/icons-material";
import { EditNoteDialog } from "./EditNoteDialog";
import { ExpandableCell } from "./ExpandableCell";
import { UploadImageDialog } from "./UploadImageDialog";
import { ViewImagesDialog } from "./ViewImagesDialog";
import { createActionsColumn } from "../../../../../utils/datagrid.utils";
import { useFormTranslator } from "../../../../../hooks/useFormTranslator";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface NotesInfoProps {
  customer: Customer | undefined;
  addNote: AddCustomerNoteViewCallback;
  editNote: EditCustomerNoteViewCallback;
  deleteNote: DeleteCustomerNoteViewCallback;
  uploadNoteImage: UploadCustomerNoteImageCallback;
}

export function NotesInfo({
  customer,
  addNote,
  editNote,
  deleteNote,
  uploadNoteImage
}: NotesInfoProps) {
  const { t } = useTranslation();
  const translator = useFormTranslator("customer_note");

  const newestNote = useMemo(() => {
    const allDates = customer?.notes.map(m => m.createdDate) ?? [];
    return fromUnixTime(Math.max(...allDates));
  }, [customer?.notes]);

  const columns = useMemo<GridColDef<CustomerNote>[]>(
    () => [
      {
        field: "note",
        headerName: translator.getField("note").getName(),
        hideable: false,
        flex: 1,
        renderCell: params => (
          <ExpandableCell
            {...params}
            defaultExpanded={isEqual(
              newestNote,
              fromUnixTime(params.row.createdDate),
            )}
          />
        ),
      },
      {
        field: "createdDate",
        headerName: translator.getField("createdDate").getName(),
        hideable: false,
        sortable: true,
        type: "date",
        valueGetter: params => fromUnixTime(params.value),
      },
      createActionsColumn((params) => [
        <ViewImagesDialog
          key={`customer_notes_action_view_images_${params.row.id}`}
          customerId={customer?.id ?? 0}
          note={params.row}
        />,
        <UploadImageDialog
          key={`customer_notes_action_upload_image_${params.row.id}`}
          noteId={params.row.id}
          uploadNoteImage={uploadNoteImage}
        />,
        <EditNoteDialog
          key={`customer_notes_action_edit_${params.row.id}`}
          note={params.row}
          editNote={editNote}
        />,
        <ConfirmButton
          key={`customer_notes_action_delete_${params.row.id}`}
          confirmText={t("pages.customers.view.texts.warning_remove_note")}
          icon
          isDanger
          onConfirm={() => deleteNote(params.row.id)}
        >
          <Delete color="error" />
        </ConfirmButton>,
      ],
      ),
    ],
    [customer?.id, deleteNote, editNote, newestNote, t, translator, uploadNoteImage],
  );

  return (
    <Card>
      <CardHeader
        title={t("pages.customers.view.cards.notes")}
        action={<AddNoteDialog addNote={addNote} />}
      />
      <CardContent>
        <ApplicationStandardDataGrid
          columns={columns}
          rows={customer?.notes ?? []}
          getRowId={row => row.id}
          autoHeight
          getRowHeight={() => "auto"}
          disableSelectionOnClick
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: "createdDate",
                  sort: "desc",
                },
              ],
            },
          }}
          sx={{
            [`&.${gridClasses["root--densityCompact"]} .${gridClasses.cell}`]: {
              py: 1,
            },
            [`&.${gridClasses["root--densityStandard"]} .${gridClasses.cell}`]:
              { py: 2 },
            [`&.${gridClasses["root--densityComfortable"]} .${gridClasses.cell}`]:
              { py: 3 },
          }}
        />
      </CardContent>
    </Card>
  );
}
