import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";
import { Layout } from "./components/Layout";
import { OidcSecure } from "@axa-fr/react-oidc";
import { PageRoot } from "./components/Layout/PageRoot";
import { PagesFinder } from "./pages";
import { useTranslation } from "react-i18next";

export function Router() {
    const { t } = useTranslation();

    const pages = PagesFinder.getPages(undefined, true);

    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<Navigate to="/app/dashboard" replace />} />

                <Route
                    path="app"
                    element={
                        <OidcSecure>
                            <Layout>
                                <Outlet />
                            </Layout>
                        </OidcSecure>
                    }
                >
                    {pages.map((page, idx) => {
                        const RouteElement = page.element;
                        return (
                            <Route
                                key={idx}
                                path={page.path}
                            >
                                <Route
                                    index
                                    element={
                                        <PageRoot title={t(page.name)}>
                                            <RouteElement />
                                        </PageRoot>
                                    }
                                />
                                {page.children?.map((childPage, idy) => {
                                    const ChildRouteElement = childPage.element;
                                    return (
                                        <Route
                                            key={idy}
                                            path={childPage.path}
                                            element={
                                                <PageRoot title={t(childPage.title ?? page.name)}>
                                                    <ChildRouteElement />
                                                </PageRoot>
                                            }
                                        />
                                    )
                                })}
                            </Route>
                        );
                    })}
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
