import { ButtonGroup, Divider, Grid } from "@mui/material";
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarProps, GridToolbarQuickFilter, useGridRootProps } from "@mui/x-data-grid";

export function ApplicationDataGridToolbar(props: GridToolbarProps) {
    const rootProps = useGridRootProps();

    if (rootProps.disableColumnFilter && rootProps.disableColumnSelector && rootProps.disableDensitySelector && !props.showQuickFilter) {
        return <></>
    }

    const showFilter = !rootProps.disableColumnFilter || rootProps.columns.some(s => s.filterable);

    return (
        <>
            <GridToolbarContainer sx={{ padding: 1 }}>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <ButtonGroup>
                            <GridToolbarColumnsButton />
                            {showFilter && <GridToolbarFilterButton />}
                            <GridToolbarDensitySelector />
                        </ButtonGroup>
                    </Grid>
                    {props.showQuickFilter && (
                        <Grid item>
                            <GridToolbarQuickFilter showQuickFilter />
                        </Grid>
                    )}
                </Grid>
            </GridToolbarContainer>
            <Divider />
        </>
    );
}