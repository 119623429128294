import "swiper/css";
import "swiper/css/pagination";

import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { useCallback, useState } from "react";
import { CustomerNote } from "../../../../../models/customer/CustomerNote";
import { Environment } from "../../../../../env";
import { Image } from "@mui/icons-material";
import { Pagination } from "swiper/modules";
import { useTranslation } from "react-i18next";

interface ViewImagesDialogProps {
    customerId: number;
    note: CustomerNote;
}

export function ViewImagesDialog({ customerId, note }: ViewImagesDialogProps) {
    const { t } = useTranslation();

    const [opened, setOpened] = useState(false);

    const onOpen = useCallback(() => setOpened(true), [setOpened]);
    const onClose = useCallback(() => setOpened(false), []);

    return (
        <>
            <IconButton onClick={onOpen}>
                <Image color={note.images.length > 0 ? "primary" : undefined} />
            </IconButton>
            <Dialog open={opened} fullWidth onClose={onClose}>
                <DialogTitle>
                    {t("pages.customers.view.notes.header_images_list")}
                </DialogTitle>
                <DialogContent>
                    {note.images.length > 0 ? (
                        <Swiper slidesPerView={1} pagination modules={[Pagination]}>
                            {note.images.map(image => (
                                <SwiperSlide key={`note_image_${image}`} style={{ textAlign: "center" }}>
                                    <img src={`${Environment.API_HOST}/api/customers/${customerId}/notes/images/${image}`} style={{ width: "100%" }} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    ) : (
                        <p>{t("pages.customers.view.notes.no_images")}</p>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
}
