import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
} from "@mui/material";
import { useCallback, useState } from "react";
import { AddPhotoAlternate } from "@mui/icons-material";
import { CustomerNoteImageCreate } from "../../../../../models/customer/CustomerNoteImage";
import { FormikFileInput } from "../../../../../components/Controls/Formik/FileInput";
import { FormikSubmitButton } from "../../../../../components/Controls/Formik/SubmitButton";
import { UploadCustomerNoteImageCallback } from "../../../../../service/CustomerService";
import { useFormTranslator } from "../../../../../hooks/useFormTranslator";
import { useFormik } from "formik";
import { useImageValidationSchema } from "./schema";
import { useTranslation } from "react-i18next";

interface UploadImageDialogProps {
    noteId: number;
    uploadNoteImage: UploadCustomerNoteImageCallback;
}

const initialValues: CustomerNoteImageCreate = {
    files: []
}

export function UploadImageDialog({ noteId, uploadNoteImage }: UploadImageDialogProps) {
    const { t } = useTranslation();
    const customerNoteImageTranslator = useFormTranslator("customer_note_image");
    const { validationSchema, isRequired } = useImageValidationSchema(
        customerNoteImageTranslator,
    );

    const [opened, setOpened] = useState(false);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async values => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            await uploadNoteImage(noteId, values.files);
            onClose();
        },
    });

    const onOpen = useCallback(() => setOpened(true), [setOpened]);
    const onClose = useCallback(() => {
        setOpened(false);
        formik.resetForm();
    }, [formik]);

    return (
        <>
            <IconButton onClick={onOpen}>
                <AddPhotoAlternate />
            </IconButton>
            <Dialog open={opened} fullWidth>
                <DialogTitle>
                    {t("common.edit", { resource: t("pages.customers.resource_note_image") })}
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2} marginY={1}>
                        <FormikFileInput
                            formik={formik}
                            translator={customerNoteImageTranslator}
                            field="files"
                            required={isRequired("files")}
                            fullWidth
                            accept="image/*"
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button color="error" variant="outlined" onClick={onClose}>
                        {t("common.cancel")}
                    </Button>
                    <FormikSubmitButton formik={formik} />
                </DialogActions>
            </Dialog>
        </>
    );
}
