import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";
import { Customer } from "../../../../models/customer/Customer";
import { FormTranslator } from "../../../../hooks/useFormTranslator";
import { useTranslation } from "react-i18next";

interface DiseaseInfoProps {
  customer: Customer | undefined;
  translator: FormTranslator;
}

export function DiseaseInfo({ customer, translator }: DiseaseInfoProps) {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader title={t("pages.customers.view.cards.diseases")} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Box display="flex" flexDirection="column" gap={3}>
              <FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={customer?.diseases.diabetes ?? false}
                        onChange={() => null}
                      />
                    }
                    label={translator.getField("diseases.diabetes").getName()}
                  />
                </FormGroup>
                {customer?.diseases.diabetes && (
                  <TextField
                    value={customer.diseases.diabetesAdd ?? ""}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    onChange={() => null}
                  />
                )}
              </FormGroup>

              <FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={customer?.diseases.heartVascular ?? false}
                        onChange={() => null}
                      />
                    }
                    label={translator
                      .getField("diseases.heartVascular")
                      .getName()}
                  />
                </FormGroup>
                {customer?.diseases.heartVascular && (
                  <TextField
                    value={customer?.diseases.heartVascularAdd ?? ""}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    onChange={() => null}
                  />
                )}
              </FormGroup>

              <FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={customer?.diseases.antiCoagulants ?? false}
                        onChange={() => null}
                      />
                    }
                    label={translator
                      .getField("diseases.antiCoagulants")
                      .getName()}
                  />
                </FormGroup>
                {customer?.diseases.antiCoagulants && (
                  <TextField
                    value={customer?.diseases.antiCoagulantsAdd ?? ""}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    onChange={() => null}
                  />
                )}
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={customer?.diseases.rheumatism ?? false}
                      onChange={() => null}
                    />
                  }
                  label={translator.getField("diseases.rheumatism").getName()}
                />
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={customer?.diseases.allergy ?? false}
                      onChange={() => null}
                    />
                  }
                  label={translator.getField("diseases.allergy").getName()}
                />
                {customer?.diseases.allergy && (
                  <TextField
                    value={customer?.diseases.allergyAdd ?? ""}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    onChange={() => null}
                  />
                )}
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={customer?.diseases.elasticStockings ?? false}
                      onChange={() => null}
                    />
                  }
                  label={translator
                    .getField("diseases.elasticStockings")
                    .getName()}
                />
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={customer?.diseases.archSupports ?? false}
                      onChange={() => null}
                    />
                  }
                  label={translator.getField("diseases.archSupports").getName()}
                />
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        customer?.diseases.customizedReadyToWearShoes ?? false
                      }
                      onChange={() => null}
                    />
                  }
                  label={translator
                    .getField("diseases.customizedReadyToWearShoes")
                    .getName()}
                />
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={customer?.diseases.orthopedicShoes ?? false}
                      onChange={() => null}
                    />
                  }
                  label={translator
                    .getField("diseases.orthopedicShoes")
                    .getName()}
                />
              </FormGroup>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box display="flex" flexDirection="column" gap={3}>
              <TextField
                label={translator.getField("diseases.feetType").getName()}
                value={customer?.diseases.feetType ?? ""}
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={() => null}
              />

              <TextField
                label={translator
                  .getField("diseases.orthopedicAbnormalities")
                  .getName()}
                value={customer?.diseases.orthopedicAbnormalities ?? ""}
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={() => null}
              />

              <TextField
                label={translator.getField("diseases.skinCondition").getName()}
                value={customer?.diseases.skinCondition ?? ""}
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={() => null}
              />

              <TextField
                label={translator.getField("diseases.skinAffections").getName()}
                value={customer?.diseases.skinAffections ?? ""}
                multiline
                rows={4}
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={() => null}
              />

              <TextField
                label={translator.getField("diseases.nailCondition").getName()}
                value={customer?.diseases.nailCondition ?? ""}
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={() => null}
              />

              <TextField
                label={translator.getField("diseases.nailAffections").getName()}
                value={customer?.diseases.nailAffections ?? ""}
                multiline
                rows={4}
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={() => null}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
