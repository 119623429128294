import { Role } from "./models/user/Role";

export interface Permission {
    roles: Role[];
}

interface Permissions {
    Dashboard: Permission;
    CustomersManage: Permission;
    CustomersView: Permission;
}

const IMPORTANT = [Role.Admin];

export const Permissions: Permissions = {
    Dashboard: {
        roles: IMPORTANT,
    },
    CustomersManage: {
        roles: IMPORTANT,
    },
    CustomersView: {
        roles: IMPORTANT,
    },
};
