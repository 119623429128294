export interface CustomerAffection extends CustomerAffectionCreate {
    id: number;
}

export interface CustomerAffectionCreate {
    categoryId: AffectionCategory;
    sideId: AffectionSide;
    positionX: number;
    positionY: number;
}

export enum AffectionCategory {
    CALLUS = "CALLUS",
    CORN = "CORN",
    VERRUCA = "VERRUCA",
    MYCOSIS = "MYCOSIS",
    CLEFT = "CLEFT",
    NAIL_AFFECTION = "NAIL_AFFECTION",
    WOUND = "WOUND"
}

export enum AffectionSide {
    DORSAL = "DORSAL",
    PLANTAR = "PLANTAR"
}