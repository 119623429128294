import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, IconButton, Stack, Typography } from "@mui/material";
import { Customer, CustomerEdit, CustomerGender } from "../../../../../models/customer/Customer";
import { useCallback, useMemo, useState } from "react";
import { Edit } from "@mui/icons-material";
import { EditCustomerViewCallback } from "../../../../../service/CustomerService";
import { FormikCheckbox } from "../../../../../components/Controls/Formik/CheckBox";
import { FormikDateInput } from "../../../../../components/Controls/Formik/DateInput";
import { FormikRadio } from "../../../../../components/Controls/Formik/Radio";
import { FormikSubmitButton } from "../../../../../components/Controls/Formik/SubmitButton";
import { FormikTextField } from "../../../../../components/Controls/Formik/TextField";
import { fromUnixTime } from "date-fns";
import { useFormTranslator } from "../../../../../hooks/useFormTranslator";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useValidationSchema } from "../../../schema";

interface EditCustomerDialogProps {
    customer: Customer;
    editCustomer: EditCustomerViewCallback;
}

export function EditCustomerDialog({ customer, editCustomer }: EditCustomerDialogProps) {
    const { t } = useTranslation();
    const customerTranslator = useFormTranslator("customer");
    const { validationSchema, isRequired } = useValidationSchema(customerTranslator);

    const [opened, setOpened] = useState(false);

    const customerEdit = useMemo<CustomerEdit>(() => ({
        ...customer,
        dateOfBirth: fromUnixTime(customer.dateOfBirth)
    }), [customer]);

    const formik = useFormik({
        initialValues: customerEdit,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async values => {
            await editCustomer(values);
            onClose();
        }
    });

    const genderOptions = useMemo(() => Object.values(CustomerGender).map(gender => ({
        value: gender,
        text: customerTranslator.getField("gender").getEnum(gender)
    })), [customerTranslator]);

    const onOpen = useCallback(() => setOpened(true), [setOpened]);
    const onClose = useCallback(() => {
        setOpened(false);
        formik.resetForm();
    }, [formik]);

    return (
        <>
            <IconButton onClick={onOpen}>
                <Edit />
            </IconButton>
            <Dialog open={opened} fullWidth>
                <DialogTitle>{t("common.edit", { resource: t("pages.customers.resource") })}</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} marginY={1}>
                        <FormikRadio
                            formik={formik}
                            translator={customerTranslator}
                            field="gender"
                            options={genderOptions}
                            row
                        />

                        <FormikTextField
                            formik={formik}
                            translator={customerTranslator}
                            field="name"
                            required={isRequired("name")}
                            fullWidth
                        />

                        <FormikDateInput
                            formik={formik}
                            translator={customerTranslator}
                            field="dateOfBirth"
                        />

                        <FormikTextField
                            formik={formik}
                            translator={customerTranslator}
                            field="address"
                            required={isRequired("address")}
                            fullWidth
                        />

                        <FormikTextField
                            formik={formik}
                            translator={customerTranslator}
                            field="postal"
                            required={isRequired("postal")}
                            fullWidth
                        />

                        <FormikTextField
                            formik={formik}
                            translator={customerTranslator}
                            field="residence"
                            required={isRequired("residence")}
                            fullWidth
                        />

                        <FormikTextField
                            formik={formik}
                            translator={customerTranslator}
                            field="email"
                            required={isRequired("email")}
                            fullWidth
                        />

                        <FormikTextField
                            formik={formik}
                            translator={customerTranslator}
                            field="phoneNumber"
                            required={isRequired("phoneNumber")}
                            fullWidth
                        />

                        <Typography variant="h6">{t("form.customer.texts.gp")}</Typography>

                        <FormikTextField
                            formik={formik}
                            translator={customerTranslator}
                            field="gp.name"
                            required={isRequired("gp.name")}
                            fullWidth
                        />

                        <FormikTextField
                            formik={formik}
                            translator={customerTranslator}
                            field="gp.phoneNumber"
                            required={isRequired("gp.phoneNumber")}
                            fullWidth
                        />

                        <Typography variant="h6">{t("form.customer.texts.note")}</Typography>

                        <FormikTextField
                            formik={formik}
                            translator={customerTranslator}
                            field="note"
                            required={isRequired("note")}
                            fullWidth
                            multiline
                            minRows={4}
                        />

                        <Typography variant="h6">{t("form.customer.texts.diseases")}</Typography>

                        <FormGroup>
                            <FormikCheckbox
                                formik={formik}
                                field="diseases.diabetes"
                                translator={customerTranslator}
                            />

                            <FormikTextField
                                formik={formik}
                                translator={customerTranslator}
                                field="diseases.diabetesAdd"
                                label=""
                                fullWidth
                                sx={{
                                    display: formik.getFieldMeta("diseases.diabetes").value ? "initial" : "none"
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormikCheckbox
                                formik={formik}
                                field="diseases.heartVascular"
                                translator={customerTranslator}
                            />

                            <FormikTextField
                                formik={formik}
                                translator={customerTranslator}
                                field="diseases.heartVascularAdd"
                                label=""
                                fullWidth
                                sx={{
                                    display: formik.getFieldMeta("diseases.heartVascular").value ? "initial" : "none"
                                }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <FormikCheckbox
                                formik={formik}
                                field="diseases.antiCoagulants"
                                translator={customerTranslator}
                            />

                            <FormikTextField
                                formik={formik}
                                translator={customerTranslator}
                                field="diseases.antiCoagulantsAdd"
                                label=""
                                fullWidth
                                sx={{
                                    display: formik.getFieldMeta("diseases.antiCoagulants").value ? "initial" : "none"
                                }}
                            />
                        </FormGroup>

                        <FormikCheckbox
                            formik={formik}
                            field="diseases.rheumatism"
                            translator={customerTranslator}
                        />

                        <FormGroup>
                            <FormikCheckbox
                                formik={formik}
                                field="diseases.allergy"
                                translator={customerTranslator}
                            />

                            <FormikTextField
                                formik={formik}
                                translator={customerTranslator}
                                field="diseases.allergyAdd"
                                label=""
                                fullWidth
                                sx={{
                                    display: formik.getFieldMeta("diseases.allergy").value ? "initial" : "none"
                                }}
                            />
                        </FormGroup>

                        <FormikCheckbox
                            formik={formik}
                            field="diseases.elasticStockings"
                            translator={customerTranslator}
                        />

                        <FormikCheckbox
                            formik={formik}
                            field="diseases.archSupports"
                            translator={customerTranslator}
                        />

                        <FormikCheckbox
                            formik={formik}
                            field="diseases.customizedReadyToWearShoes"
                            translator={customerTranslator}
                        />

                        <FormikCheckbox
                            formik={formik}
                            field="diseases.orthopedicShoes"
                            translator={customerTranslator}
                        />

                        <FormikTextField
                            formik={formik}
                            translator={customerTranslator}
                            field="diseases.feetType"
                            required={isRequired("diseases.feetType")}
                            fullWidth
                        />

                        <FormikTextField
                            formik={formik}
                            translator={customerTranslator}
                            field="diseases.orthopedicAbnormalities"
                            required={isRequired("diseases.orthopedicAbnormalities")}
                            fullWidth
                        />

                        <FormikTextField
                            formik={formik}
                            translator={customerTranslator}
                            field="diseases.skinCondition"
                            required={isRequired("diseases.skinCondition")}
                            fullWidth
                        />

                        <FormikTextField
                            formik={formik}
                            translator={customerTranslator}
                            field="diseases.skinAffections"
                            required={isRequired("diseases.skinAffections")}
                            fullWidth
                            multiline
                            minRows={4}
                        />

                        <FormikTextField
                            formik={formik}
                            translator={customerTranslator}
                            field="diseases.nailCondition"
                            required={isRequired("diseases.nailCondition")}
                            fullWidth
                        />

                        <FormikTextField
                            formik={formik}
                            translator={customerTranslator}
                            field="diseases.nailAffections"
                            required={isRequired("diseases.nailAffections")}
                            fullWidth
                            multiline
                            minRows={4}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button color="error" variant="outlined" onClick={onClose}>{t("common.cancel")}</Button>
                    <FormikSubmitButton formik={formik} />
                </DialogActions>
            </Dialog>
        </>
    )
}