import { Box, Card, CardContent, CardHeader, TextField } from "@mui/material";
import { Customer } from "../../../../models/customer/Customer";
import { FormTranslator } from "../../../../hooks/useFormTranslator";
import { useTranslation } from "react-i18next";

interface NoteInfoProps {
    customer: Customer | undefined;
    translator: FormTranslator;
}

export function NoteInfo({ customer, translator }: NoteInfoProps) {
    const { t } = useTranslation();

    return (
        <Card>
            <CardHeader title={t("pages.customers.view.cards.note")} />
            <CardContent>
                <Box display="flex" flexDirection="column" gap={3}>
                    <TextField
                        label={translator.getField("note").getName()}
                        value={customer?.note ?? ""}
                        fullWidth
                        multiline
                        minRows={4}
                        sx={{ flex: 1 }}
                        InputLabelProps={{ shrink: true }}
                        onChange={() => null}
                    />
                </Box>
            </CardContent>
        </Card>
    )
}