import { XYCoord, useDragLayer } from "react-dnd";
import { CSSProperties } from "react";
import { Circle } from "./Circle";
import { ItemType } from "./constants";

const layerStyles: CSSProperties = {
    position: "fixed",
    pointerEvents: "none",
    zIndex: 100,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
}

function getItemStyles(initialOffset: XYCoord | null, currentOffset: XYCoord | null) {
    if (!initialOffset || !currentOffset) {
        return {
            display: "none",
        }
    }

    const { x, y } = currentOffset;

    const transform = `translate(${x}px, ${y}px)`
    return {
        backgroundColor: "transparent",
        transform,
        WebkitTransform: transform,
    }
}

export function DragLayer() {
    const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging()
    }));

    function renderItem() {
        switch (itemType) {
            case ItemType:
                return <Circle category={item.category} />
            default:
                return null
        }
    }

    if (!isDragging) {
        return null;
    }

    return (
        <div style={layerStyles}>
            <div style={getItemStyles(initialOffset, currentOffset)}>
                {renderItem()}
            </div>
        </div>
    )
}