import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { NavLink, matchPath, useLocation } from "react-router-dom";

interface RouterLinkProps extends React.PropsWithChildren {
  to: string;
  text: string;
  collapsed: boolean;
  icon?: JSX.Element;
}

export function RouterLink({ to, text, collapsed, icon }: RouterLinkProps) {
  const location = useLocation();
  const isCurrent = matchPath(
    {
      path: to,
      caseSensitive: false,
      end: false,
    },
    location.pathname,
  );

  return (
    <ListItemButton component={NavLink} to={to} selected={isCurrent !== null}>
      {icon !== undefined && (
        <ListItemIcon
          sx={{ minWidth: collapsed ? 0 : undefined }}
          title={collapsed ? text : undefined}
        >
          {icon}
        </ListItemIcon>
      )}
      {collapsed ? (
        icon ? null : (
          <ListItemText primary={text.substring(0, 1)} />
        )
      ) : (
        <ListItemText primary={text} />
      )}
    </ListItemButton>
  );
}
