import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from "@mui/material";
import { FormValues, formikEventWrapper } from "./utils";
import { ChangeEvent } from "react";
import { FormTranslator } from "../../../hooks/useFormTranslator";
import { FormikProps } from "formik";

type FormikCheckboxProps<T extends FormValues> = {
  formik: FormikProps<T>;
  field: string;
  translator: FormTranslator;
  helperText?: string;
} & Omit<CheckboxProps, "id" | "name" | "label" | "value" | "error">;

export function FormikCheckbox<T extends FormValues>({
  formik,
  field,
  translator,
  helperText,
  onChange,
  onBlur,
  ...props
}: FormikCheckboxProps<T>) {
  const label = translator.getField(field).getName();
  const meta = formik.getFieldMeta<boolean | undefined>(field);

  const errorText = meta.error;
  const hasError = meta.touched && errorText !== undefined;
  const helpText = hasError ? errorText : helperText;

  const onChangeInternal = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    formik.setFieldValue(field, checked);
    onChange?.(event, checked);
  };
  const onBlurInternal = formikEventWrapper(formik.handleBlur, onBlur);

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            {...props}
            id={field}
            name={field}
            onChange={onChangeInternal}
            onBlur={onBlurInternal}
            checked={meta.value ?? false}
          />
        }
        label={label}
      />
      <FormHelperText error={hasError}>{helpText}</FormHelperText>
    </FormGroup>
  );
}
