import { FormValues, formikEventWrapper } from "./utils";
import { MuiFileInput, MuiFileInputProps } from "mui-file-input";
import { FormTranslator } from "../../../hooks/useFormTranslator";
import { FormikProps } from "formik";

type FormikFileInputProps<T extends FormValues> = {
    formik: FormikProps<T>;
    field: string;
    translator: FormTranslator;
    accept?: string;
} & Omit<
    Extract<MuiFileInputProps, { multiple: true }>,
    "id" | "name" | "label" | "value" | "error" | "value" | "defaultValue" | "multiple"
>;

export function FormikFileInput<T extends FormValues>({
    formik,
    field,
    translator,
    helperText,
    accept,
    onChange,
    onBlur,
    inputProps,
    ...props
}: FormikFileInputProps<T>) {
    const translatorField = translator.getField(field);
    const label = translatorField.getName();
    const meta = formik.getFieldMeta<File[]>(field);

    const hasError = meta.touched && meta.error !== undefined;
    const helpText = hasError ? meta.error : helperText;

    const handleBlur = formikEventWrapper(formik.handleBlur, onBlur);

    return (
        <MuiFileInput
            {...props}
            ref={undefined}
            id={field}
            name={field}
            label={label}
            multiple
            error={hasError}
            helperText={helpText}
            value={meta.value}
            inputProps={{
                ...inputProps,
                accept
            }}
            onChange={(files) => {
                formik.setFieldValue(field, files);
                if (onChange) {
                    onChange(files);
                }
            }}
            onBlur={handleBlur}
        />
    );
}
