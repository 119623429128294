import { DefaultLocale, SupportedLocales } from "./hooks/useLocales";
import i18n, { ResourceLanguage } from "i18next";
import { initReactI18next } from "react-i18next";
import transTranslationNL from "./translations/translation_nl.json";

const resources: Partial<Record<SupportedLocales, ResourceLanguage>> = {
    nl: {
        translation: transTranslationNL,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: DefaultLocale,
    fallbackLng: DefaultLocale,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
