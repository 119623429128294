import { FormValues, formikEventWrapper } from "./utils";
import { TextField, TextFieldProps } from "@mui/material";
import { FormTranslator } from "../../../hooks/useFormTranslator";
import { FormikProps } from "formik";

type FormikTextFieldProps<T extends FormValues> = {
  formik: FormikProps<T>;
  field: string;
  translator: FormTranslator;
} & Omit<TextFieldProps, "id" | "name" | "value" | "error">;

export function FormikTextField<T extends FormValues>({
  formik,
  field,
  translator,
  label,
  helperText,
  onChange,
  onBlur,
  ...props
}: FormikTextFieldProps<T>) {
  const actualLabel = label ?? translator.getField(field).getName();
  const meta = formik.getFieldMeta<string | undefined>(field);

  const errorText = meta.error;
  const hasError = meta.touched && errorText !== undefined;
  const helpText = hasError ? errorText : helperText;

  const onChangeInternal = formikEventWrapper(formik.handleChange, onChange);
  const onBlurInternal = formikEventWrapper(formik.handleBlur, onBlur);

  return (
    <TextField
      id={field}
      name={field}
      label={actualLabel}
      value={meta.value ?? ""}
      error={hasError}
      helperText={helpText}
      onChange={onChangeInternal}
      onBlur={onBlurInternal}
      {...props}
    />
  );
}
