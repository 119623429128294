import { CustomerIndexPage, CustomerViewPage } from "./pages/Customer";
import {
    Dashboard,
    PeopleAlt,
    SvgIconComponent,
} from "@mui/icons-material";
import { Permission, Permissions } from "./permissions";
import { ComponentType } from "react";
import { DashboardPage } from "./pages/Dashboard";
import { Role } from "./models/user/Role";
import { RoleUtils } from "./utils/role.utils";

export interface ChildPage {
    title?: string;
    path: string;
    element: ComponentType;
}

export interface Page extends ChildPage {
    name: string;
    icon?: SvgIconComponent;
    isDashboard?: true;
    isNavigation?: true;
    navigationDivider?: true;
    permission: Permission;
    children?: ChildPage[];
}

const pages: Page[] = [
    {
        name: "pages.dashboard.header",
        path: "dashboard",
        element: DashboardPage,
        icon: Dashboard,
        isNavigation: true,
        permission: Permissions.Dashboard
    },
    {
        name: "pages.customers.list.header",
        path: "customers",
        element: CustomerIndexPage,
        icon: PeopleAlt,
        isNavigation: true,
        permission: Permissions.CustomersView,
        children: [
            {
                title: "pages.customers.view.header",
                path: ":customerId",
                element: CustomerViewPage,
            },
        ],
    }
];

export class PagesFinder {
    private static getPagesForRoles(role?: Role) {
        return pages.filter(
            f =>
                role === undefined ||
                f.permission.roles.findIndex(f => f === role) !== -1,
        );
    }

    static getPages(idToken: string | undefined, forceAll?: boolean): Page[] {
        const force = forceAll ?? false;

        let isLoggedIn = false;
        let role: Role | undefined = undefined;
        if (idToken !== undefined) {
            isLoggedIn = true;
            role = RoleUtils.getRoleFromIdToken(idToken);
        }

        if (isLoggedIn || force) {
            return this.getPagesForRoles(role);
        }

        return [];
    }
}
