import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Delete, Edit, Email, PhoneAndroid } from "@mui/icons-material";
import {
  DeleteCustomerViewCallback,
  EditCustomerViewCallback,
} from "../../../../../service/CustomerService";
import { useCallback, useMemo } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ConfirmButton } from "../../../../../components/Controls/ConfirmButton";
import { Customer } from "../../../../../models/customer/Customer";
import { EditCustomerDialog } from "./EditCustomerDialog";
import { FormTranslator } from "../../../../../hooks/useFormTranslator";
import { fromUnixTime } from "date-fns";
import { useLocales } from "../../../../../hooks/useLocales";
import { useNavigate } from "react-router-dom";

interface BasicInfoProps {
  customer: Customer | undefined;
  translator: FormTranslator;
  editCustomer: EditCustomerViewCallback;
  deleteCustomer: DeleteCustomerViewCallback;
}

export function BasicInfo({
  customer,
  translator,
  editCustomer,
  deleteCustomer,
}: BasicInfoProps) {
  const { t, libraries } = useLocales();
  const navigate = useNavigate();

  const dateOfBirth = useMemo(() => {
    if (customer?.dateOfBirth) {
      try {
        return new AdapterDateFns({ locale: libraries.datefns }).format(
          fromUnixTime(customer.dateOfBirth),
          "fullDate",
        );
      } catch {
        return "";
      }
    }
    return "";
  }, [customer?.dateOfBirth, libraries.datefns]);

  const deleteInternal = useCallback(async () => {
    await deleteCustomer();
    navigate("/app/customers");
  }, [deleteCustomer, navigate]);

  return (
    <Card>
      <CardHeader
        title={t("pages.customers.view.cards.basic")}
        action={
          <>
            {customer ? (
              <EditCustomerDialog
                customer={customer}
                editCustomer={editCustomer}
              />
            ) : (
              <IconButton disabled>
                <Edit />
              </IconButton>
            )}
            <ConfirmButton
              confirmText={t("pages.customers.view.texts.warning_remove")}
              color="error"
              icon
              isDanger
              onConfirm={deleteInternal}
            >
              <Delete />
            </ConfirmButton>
          </>
        }
      />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item lg={3} md={6} xs={12}>
            <TextField
              label={translator.getField("name").getName()}
              value={
                customer
                  ? translator.getField("gender").getEnum(customer.gender) +
                    " " +
                    customer.name
                  : ""
              }
              sx={{ flex: 1 }}
              InputLabelProps={{ shrink: true }}
              onChange={() => null}
              fullWidth
            />
          </Grid>

          <Grid item lg={3} md={6} xs={12}>
            <TextField
              label={translator.getField("dateOfBirth").getName()}
              value={dateOfBirth ?? new Date()}
              InputLabelProps={{ shrink: true }}
              onChange={() => null}
              fullWidth
            />
          </Grid>

          <Grid item lg={3} md={6} xs={12}>
            <TextField
              label={translator.getField("email").getName()}
              value={customer?.email ?? ""}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                ),
              }}
              onChange={() => null}
              fullWidth
            />
          </Grid>

          <Grid item lg={3} md={6} xs={12}>
            <TextField
              label={translator.getField("phoneNumber").getName()}
              value={customer?.phoneNumber ?? ""}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneAndroid />
                  </InputAdornment>
                ),
              }}
              onChange={() => null}
              fullWidth
            />
          </Grid>

          <Grid item lg={4} md={6} xs={12}>
            <TextField
              label={translator.getField("address").getName()}
              value={customer?.address ?? ""}
              sx={{ flex: 1 }}
              InputLabelProps={{ shrink: true }}
              onChange={() => null}
              fullWidth
            />
          </Grid>

          <Grid item lg={4} md={6} xs={12}>
            <TextField
              label={translator.getField("postal").getName()}
              value={customer?.postal ?? ""}
              InputLabelProps={{ shrink: true }}
              onChange={() => null}
              fullWidth
            />
          </Grid>

          <Grid item lg={4} md={6} xs={12}>
            <TextField
              label={translator.getField("residence").getName()}
              value={customer?.residence ?? ""}
              InputLabelProps={{ shrink: true }}
              onChange={() => null}
              fullWidth
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
