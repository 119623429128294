import { Box, Divider, Typography } from "@mui/material";
import { Helmet, HelmetProps } from "react-helmet-async";
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Environment } from "../../env";
import { useTranslation } from "react-i18next";

interface PageRootProps {
  title: string;
}

export function PageRoot({
  title,
  children,
}: PropsWithChildren<PageRootProps>) {
  const { t } = useTranslation();

  const headerFormat = useMemo(() => ` | ${t("app")}`, [t]);
  const stripFormat = useCallback(
    (header: string) => header.replace(headerFormat, ""),
    [headerFormat],
  );

  const [strippedTitle, setStrippedTitle] = useState(title);

  const updateClientState = useCallback(
    (newState: HelmetProps) => {
      setStrippedTitle(stripFormat(newState.title ?? ""));
    },
    [stripFormat, setStrippedTitle],
  );

  useEffect(() => {
    setStrippedTitle(title);
  }, [title]);

  return (
    <>
      <Helmet
        title={title}
        titleTemplate={`%s${headerFormat}`}
        onChangeClientState={updateClientState}
      >
        <link
          href={`${Environment.API_HOST}/api/metadata/favicon?variant=ICON`}
          rel="icon"
        />
        <link
          href={`${Environment.API_HOST}/api/metadata/favicon?variant=IMAGE_192`}
          rel="apple-touch-icon"
        />
      </Helmet>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4" component="h1">
          {strippedTitle}
        </Typography>
      </Box>
      <Divider sx={{ marginY: 2 }} />
      {children}
    </>
  );
}
