import {
  Box,
  CssBaseline,
  ThemeProvider,
  createTheme,
  useTheme,
} from "@mui/material";
import { PropsWithChildren, useMemo } from "react";
import { DrawerLayout } from "./DrawerLayout";
import { useLocales } from "../../hooks/useLocales";

export function Layout({ children }: PropsWithChildren) {
  const { libraries } = useLocales();
  const theme = useTheme();

  const themeWithLocale = useMemo(
    () => createTheme(theme, libraries.material),
    [libraries, theme],
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <ThemeProvider theme={themeWithLocale}>
        <DrawerLayout>{children}</DrawerLayout>
      </ThemeProvider>
    </Box>
  );
}
