import { AffectionCategoryDragObject, AffectionCategoryDropResult } from "./types";
import { AffectionSide, CustomerAffection } from "../../../../../models/customer/CustomerAffection";
import { Box, useTheme } from "@mui/material";
import { XYCoord, useDrop } from "react-dnd";
import { Circle } from "./Circle";
import { DeleteCustomerAffectionViewCallback } from "../../../../../service/CustomerService";
import { ItemType } from "./constants";
import { mergeRefs } from "react-merge-refs";
import { useRef } from "react";

interface DropItemProps {
    side: AffectionSide;
    results: CustomerAffection[];
    deleteEffection: DeleteCustomerAffectionViewCallback;
}

interface CollectedProps {
    canDrop: boolean;
    isOver: boolean;
}

const getPercentages = (bounds: DOMRect | undefined, coords: XYCoord | null) => {
    if (bounds && coords) {
        return {
            x: Math.round((coords.x - bounds.left) / bounds.width * 10000) / 100,
            y: Math.round((coords.y - bounds.top) / bounds.height * 10000) / 100
        }
    }
}

const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

export function FeetImageDrop({ side, results, deleteEffection }: DropItemProps) {
    const ref = useRef<HTMLDivElement>();
    const theme = useTheme();

    const [{ canDrop, isOver }, drop] = useDrop<AffectionCategoryDragObject, AffectionCategoryDropResult, CollectedProps>(() => ({
        accept: ItemType,
        drop: (item, monitor) => {
            const percentages = getPercentages(ref.current?.getBoundingClientRect(), monitor.getSourceClientOffset());
            if (percentages) {
                return {
                    category: item.category,
                    side,
                    positionX: clamp(percentages.x, 0, 100),
                    positionY: clamp(percentages.y, 0, 100),
                    x: monitor.getSourceClientOffset()?.x,
                    x2: ref.current?.clientWidth
                }
            } else {
                return {
                    category: item.category,
                    side,
                    positionX: undefined,
                    positionY: undefined
                }
            }
        },
        collect: monitor => ({
            canDrop: monitor.canDrop(),
            isOver: monitor.isOver({ shallow: true })
        })
    }), [side]);

    const borderColor = theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.23)" : "rgba(255, 255, 255, 0.23)";
    return (
        <Box
            ref={mergeRefs([drop, ref])}
            position="relative"
            sx={{
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: canDrop && isOver ? theme.palette.text.primary : borderColor,
                borderRadius: theme.shape.borderRadius,
                "&:hover": {
                    borderColor: theme.palette.text.primary
                }
            }}
        >
            <img src={`/feet_${side === AffectionSide.DORSAL ? "dorsal" : "plantar"}.png`} style={{ width: "100%" }} />
            {results.filter(f => f.sideId === side).map((item, idx) => (
                <Box
                    key={`feet_result_point_${idx}`}
                    sx={{
                        left: `${item.positionX}%`,
                        top: `${item.positionY}%`,
                        position: "absolute"
                    }}
                    onClick={() => deleteEffection(item.id)}
                >
                    <Circle category={item.categoryId} />
                </Box>
            ))}
        </Box>
    )
}