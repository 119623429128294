import { DataGrid, DataGridProps, GridLinkOperator, GridValidRowModel } from "@mui/x-data-grid";
import { ApplicationDataGridToolbar } from "./Toolbar";
import { Theme } from "@mui/material";
import { useLocales } from "../../../hooks/useLocales";

export type ApplicationStandardDataGridProps<R extends GridValidRowModel> = Omit<DataGridProps<R>, "localeText">;

export function ApplicationStandardDataGrid<R extends GridValidRowModel>(props: ApplicationStandardDataGridProps<R>) {
    const { libraries } = useLocales();

    return (
        <DataGrid
            {...props}
            localeText={libraries.datagrid.components.MuiDataGrid.defaultProps.localeText}
            components={{
                Toolbar: ApplicationDataGridToolbar,
            }}
            componentsProps={{
                toolbar: {
                    showQuickFilter: false
                },
                filterPanel: {
                    linkOperators: [GridLinkOperator.And],
                    columnsSort: "asc",
                    filterFormProps: {
                        linkOperatorInputProps: {
                            variant: "outlined",
                            size: "small",
                        },
                        columnInputProps: {
                            variant: "outlined",
                            size: "small",
                            sx: { mt: "auto" },
                        },
                        operatorInputProps: {
                            variant: "outlined",
                            size: "small",
                            sx: { mt: "auto" },
                        },
                        deleteIconProps: {
                            sx: {
                                "& .MuiSvgIcon-root": { color: "#d32f2f" },
                            },
                        },
                    },
                    sx: {
                        "& .MuiDataGrid-filterForm": { p: 2 },
                        "& .MuiDataGrid-filterForm:nth-of-type(even)": {
                            backgroundColor: (theme: Theme) => theme.palette.mode === "dark" ? "#444" : "#f5f5f5",
                        },
                        "& .MuiDataGrid-filterFormLinkOperatorInput": { mr: 2 },
                        "& .MuiDataGrid-filterFormColumnInput": { mr: 2, minWidth: 150 },
                        "& .MuiDataGrid-filterFormOperatorInput": { mr: 2 },
                        "& .MuiDataGrid-filterFormValueInput": { minWidth: 200 },
                    },
                },
            }}
        />
    )
}