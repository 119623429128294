import { CustomerAffection } from "./CustomerAffection";
import { CustomerNote } from "./CustomerNote";

export interface CustomerEdit extends CustomerAdd {
  id: number;
}

export interface CustomerAdd extends CommonFields {
  dateOfBirth: Date;
}

export interface Customer extends CommonFields {
  id: number;
  dateOfBirth: number;
  affections: CustomerAffection[];
  notes: CustomerNote[];
}

export interface CustomerOverview {
  id: number;
  name: string;
  gender: CustomerGender;
  address: string | undefined;
  postal: string | undefined;
  residence: string | undefined;
}

export enum CustomerGender {
  Mr = "Mr",
  Mrs = "Mrs",
  Ms = "Ms",
}

interface GPInformation {
  name: string | undefined;
  phoneNumber: string | undefined;
}

export interface DiseaseInformation {
  diabetes: boolean;
  diabetesAdd: string | undefined;
  heartVascular: boolean;
  heartVascularAdd: string | undefined;
  antiCoagulants: boolean;
  antiCoagulantsAdd: string | undefined;
  rheumatism: boolean;
  allergy: boolean;
  allergyAdd: string | undefined;
  elasticStockings: boolean;
  feetType: string | undefined;
  orthopedicAbnormalities: string | undefined;
  archSupports: boolean;
  customizedReadyToWearShoes: boolean;
  orthopedicShoes: boolean;
  skinCondition: string | undefined;
  skinAffections: string | undefined;
  nailCondition: string | undefined;
  nailAffections: string | undefined;
}

interface CommonFields {
  name: string;
  gender: CustomerGender;
  address: string | undefined;
  postal: string | undefined;
  residence: string | undefined;
  email: string | undefined;
  phoneNumber: string | undefined;
  note: string | undefined;
  gp: GPInformation;
  diseases: DiseaseInformation;
}
