import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack } from "@mui/material";
import { useCallback, useState } from "react";
import { Add } from "@mui/icons-material";
import { AddCustomerNoteViewCallback } from "../../../../../service/CustomerService";
import { CustomerNoteCreate } from "../../../../../models/customer/CustomerNote";
import { FormikSubmitButton } from "../../../../../components/Controls/Formik/SubmitButton";
import { FormikTextField } from "../../../../../components/Controls/Formik/TextField";
import { useFormTranslator } from "../../../../../hooks/useFormTranslator";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useValidationSchema } from "./schema";

interface AddNoteDialogProps {
    addNote: AddCustomerNoteViewCallback;
}

const emptyNote: CustomerNoteCreate = {
    note: ""
};

export function AddNoteDialog({ addNote }: AddNoteDialogProps) {
    const { t } = useTranslation();
    const customerNoteTranslator = useFormTranslator("customer_note");
    const { validationSchema, isRequired } = useValidationSchema(customerNoteTranslator);

    const [opened, setOpened] = useState(false);

    const formik = useFormik({
        initialValues: emptyNote,
        validationSchema,
        onSubmit: async values => {
            await addNote(values);
            onClose();
        }
    });

    const onOpen = useCallback(() => setOpened(true), [setOpened]);
    const onClose = useCallback(() => {
        setOpened(false);
        formik.resetForm();
    }, [formik]);

    return (
        <>
            <IconButton onClick={onOpen}>
                <Add />
            </IconButton>
            <Dialog open={opened} fullWidth>
                <DialogTitle>{t("common.add", { resource: t("pages.customers.resource_note") })}</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} marginY={1}>
                        <FormikTextField
                            formik={formik}
                            translator={customerNoteTranslator}
                            field="note"
                            required={isRequired("note")}
                            fullWidth
                            multiline
                            minRows={4}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button color="error" variant="outlined" onClick={onClose}>{t("common.cancel")}</Button>
                    <FormikSubmitButton formik={formik} />
                </DialogActions>
            </Dialog>
        </>
    )
}