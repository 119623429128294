import * as yup from "yup";
import { useCallback, useMemo } from "react";
import { AnyObject } from "yup/lib/types";
import { FormTranslator } from "./useFormTranslator";
import { isRequired } from "../utils/yup.utils";
import { useTranslation } from "react-i18next";

export function useYup(validationSchema: yup.ObjectSchema<AnyObject>, translator: FormTranslator) {
    const { t } = useTranslation();

    const isRequiredInternal = useCallback((key: string) => isRequired(validationSchema, key), [validationSchema]);

    yup.setLocale({
        mixed: {
            required: msg => {
                return t("errors.required", { name: translator.getField(msg.path).getName() });
            }
        },
        string: {
            email: msg => {
                return t("errors.email", { name: translator.getField(msg.path).getName() });
            }
        }
    });

    return useMemo(
        () => ({
            validationSchema,
            isRequired: isRequiredInternal,
        }),
        [isRequiredInternal, validationSchema],
    );
}