import {
  AffectionCategory,
  CustomerAffectionCreate,
} from "../../../../../models/customer/CustomerAffection";
import {
  AffectionCategoryDragObject,
  AffectionCategoryDropResult,
} from "./types";
import { Box } from "@mui/system";
import { Circle } from "./Circle";
import { ItemType } from "./constants";
import { Typography } from "@mui/material";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useDrag } from "react-dnd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface DragItemProps {
  category: AffectionCategory;
  onDragEnd: (result: CustomerAffectionCreate) => void;
}

interface CollectedProps {
  opacity: number;
}

export function AffectionCategoryDrag({ category, onDragEnd }: DragItemProps) {
  const { t } = useTranslation();

  const [{ opacity }, drag, preview] = useDrag<
    AffectionCategoryDragObject,
    AffectionCategoryDropResult,
    CollectedProps
  >(
    () => ({
      type: ItemType,
      item: {
        category,
      },
      options: {
        dropEffect: "copy",
      },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();
        if (
          item &&
          dropResult &&
          dropResult.positionX &&
          dropResult.positionY
        ) {
          onDragEnd({
            categoryId: dropResult.category,
            sideId: dropResult.side,
            positionX: dropResult.positionX,
            positionY: dropResult.positionY,
          });
        }
      },
      collect: monitor => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [category],
  );

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  return (
    <Box ref={preview} display="flex" gap={1} sx={{ opacity }}>
      <Circle category={category} ref={drag} />
      <Typography variant="body1" sx={{ overflowWrap: "anywhere" }}>
        {t(`pages.customers.view.affections.${category}`)}
      </Typography>
    </Box>
  );
}
