import jwtDecode, { JwtPayload } from "jwt-decode";
import { Role } from "../models/user/Role";

type ExtendedJwtPayload = JwtPayload & {
    role: Role;
};

export class RoleUtils {
    static getRoleFromIdToken(idToken: string): Role {
        let role = Role.User;
        if (idToken !== undefined) {
            const claims = jwtDecode<ExtendedJwtPayload>(idToken);
            role = claims.role;
        }
        return role;
    }
}
