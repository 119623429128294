import { Button, ButtonProps, IconButton } from "@mui/material";
import { MouseEvent as ReactMouseEvent, useState } from "react";
import { ConfirmButtonDialog } from "./ConfirmButtonDialog";

interface ConfirmButtonProps extends Omit<ButtonProps, "onClick" | "disabled"> {
    confirmText: string;
    onConfirm: () => void;
    icon?: boolean;
    isDanger?: boolean;
}

export function ConfirmButton({ confirmText, onConfirm, icon = false, isDanger = false, ...props }: ConfirmButtonProps) {
    const [confirmOpened, setConfirmOpened] = useState(false);

    function handleClick(e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) {
        e.stopPropagation();
        setConfirmOpened(true);
    }

    function handleConfirmClose(confirmed: boolean) {
        setConfirmOpened(false);
        if (confirmed) {
            onConfirm();
        }
    }

    return (
        <>
            {icon ? (
                <IconButton
                    {...props}
                    onClick={handleClick}
                    disabled={confirmOpened}
                >
                    {props.children}
                </IconButton>
            ) : (
                <Button
                    {...props}
                    onClick={handleClick}
                    disabled={confirmOpened}
                >
                    {props.children}
                </Button>
            )}
            <ConfirmButtonDialog open={confirmOpened} text={confirmText} isDanger={isDanger} onClose={handleConfirmClose} />
        </>
    )
}