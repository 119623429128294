import {
  AddCustomerAffectionViewCallback,
  DeleteCustomerAffectionViewCallback,
} from "../../../../../service/CustomerService";
import {
  AffectionCategory,
  AffectionSide,
} from "../../../../../models/customer/CustomerAffection";
import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { AffectionCategoryDrag } from "./AffectionCategoryDrag";
import { Customer } from "../../../../../models/customer/Customer";
import { DndProvider } from "react-dnd-multi-backend";
import { DragLayer } from "./DragLayer";
import { FeetImageDrop } from "./FeetImageDrop";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import { useTranslation } from "react-i18next";

interface FeetInfoProps {
  customer: Customer | undefined;
  addAffection: AddCustomerAffectionViewCallback;
  deleteEffection: DeleteCustomerAffectionViewCallback;
}

export function FeetInfo({
  customer,
  addAffection,
  deleteEffection,
}: FeetInfoProps) {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader title={t("pages.customers.view.cards.feet")} />
      <CardContent>
        <DndProvider options={HTML5toTouch}>
          <DragLayer />
          <Grid container columnSpacing={2}>
            <Grid item xs={2}>
              {Object.values(AffectionCategory).map(category => (
                <AffectionCategoryDrag
                  key={`affection_category_${category}`}
                  category={category}
                  onDragEnd={addAffection}
                />
              ))}
            </Grid>
            <Grid item xs={5}>
              <Typography>{}</Typography>
              <FeetImageDrop
                side={AffectionSide.DORSAL}
                results={customer?.affections ?? []}
                deleteEffection={deleteEffection}
              />
            </Grid>
            <Grid item xs={5}>
              <FeetImageDrop
                side={AffectionSide.PLANTAR}
                results={customer?.affections ?? []}
                deleteEffection={deleteEffection}
              />
            </Grid>
          </Grid>
        </DndProvider>
      </CardContent>
    </Card>
  );
}
