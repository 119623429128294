import "yup-phone";
import * as yup from "yup";
import { CustomerNoteCreate } from "../../../../../models/customer/CustomerNote";
import { FormTranslator } from "../../../../../hooks/useFormTranslator";
import { useMemo } from "react";
import { useYup } from "../../../../../hooks/useYup";

export function useValidationSchema(translator: FormTranslator) {
    const validationSchema = useMemo<yup.SchemaOf<CustomerNoteCreate>>(() => {
        return yup.object().shape({
            note: yup.string().required()
        });
    }, []);

    return useYup(validationSchema, translator);
}

export function useImageValidationSchema(translator: FormTranslator) {
    const validationSchema = useMemo(() => {
        return yup.object().shape({
            files: yup.array(yup.mixed<File>().required()).required()
        });
    }, []);

    return useYup(validationSchema, translator);
}