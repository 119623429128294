import { AffectionCategory } from "../../../../../models/customer/CustomerAffection";
import { Circle as CircleIcon } from "@mui/icons-material";
import { forwardRef } from "react";

interface CircleProps {
    category: AffectionCategory;
}

function getColor(category: AffectionCategory) {
    switch (category) {
        case AffectionCategory.CORN:
            return "#ff1111";
        case AffectionCategory.CALLUS:
            return "#ffd700";
        case AffectionCategory.NAIL_AFFECTION:
            return "#0099ff";
        case AffectionCategory.MYCOSIS:
            return "#00cc44";
        case AffectionCategory.VERRUCA:
            return "#ff6000";
        case AffectionCategory.CLEFT:
            return "#a020f0";
        case AffectionCategory.WOUND:
            return "#006600";
    }
}

export const Circle = forwardRef<HTMLDivElement, CircleProps>(({ category }, ref) => {
    return (
        <div ref={ref}>
            <CircleIcon
                sx={{
                    color: getColor(category),
                    cursor: "pointer"
                }}
                fontSize="small"
            />
        </div>
    )
});
Circle.displayName = "Circle";