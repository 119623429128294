import { GetOIDCConfiguration } from "./oidc";
import { HelmetProvider } from "react-helmet-async";
import { OidcProvider } from "@axa-fr/react-oidc";
import { Router } from "./Router";
import { SWRConfig } from "swr";
import { useSWRFetcher } from "./hooks/useFetch";

function ClientProvider(props: React.PropsWithChildren) {
  const { fetcher } = useSWRFetcher();

  return (
    <SWRConfig value={{
      fetcher,
      provider: () => new Map()
    }}>
      {props.children}
    </SWRConfig>
  )
}

export function App() {
  return (
    <OidcProvider configuration={GetOIDCConfiguration()}>
      <HelmetProvider>
        <ClientProvider>
          <Router />
        </ClientProvider>
      </HelmetProvider>
    </OidcProvider>
  )
}