import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Customer } from "../../../../models/customer/Customer";
import { FormTranslator } from "../../../../hooks/useFormTranslator";
import { PhoneAndroid } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

interface GpInfoProps {
  customer: Customer | undefined;
  translator: FormTranslator;
}

export function GpInfo({ customer, translator }: GpInfoProps) {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader title={t("pages.customers.view.cards.gp")} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label={translator.getField("gp.name").getName()}
              value={customer?.gp.name ?? ""}
              fullWidth
              sx={{ flex: 1 }}
              InputLabelProps={{ shrink: true }}
              onChange={() => null}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={translator.getField("gp.phoneNumber").getName()}
              value={customer?.gp.phoneNumber ?? ""}
              fullWidth
              sx={{ flex: 1 }}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneAndroid />
                  </InputAdornment>
                ),
              }}
              onChange={() => null}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
