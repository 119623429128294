import { Pagination, SortDirection } from "../models/pagination/Pagination";
import { useCallback, useMemo, useState } from "react";
import useSWR, { SWRResponse } from "swr";
import { PagedResults } from "../models/pagination/PagedResults";

export interface PaginationControls {
    setPage: (page: number) => void;
    setItemsPerPage: (items: number) => void;
    setSort: (field: string | undefined, sortDirection: SortDirection) => void;
}

export interface UsePaginationServiceResult<TModel, TError> {
    response: SWRResponse<PagedResults<TModel>, TError>;
    paginationControls: PaginationControls;
}

export function usePaginationService<TModel, TError>(
    defaultPagination: Pagination,
    path: string,
    extraSearchParams?: URLSearchParams,
): UsePaginationServiceResult<TModel, TError> {
    const [pagination, setPagination] = useState<Pagination>(defaultPagination);

    const params = useMemo(() => {
        const params = new URLSearchParams(extraSearchParams);
        params.append("page", pagination.page.toString());
        params.append("itemsPerPage", pagination.itemsPerPage.toString());
        pagination.itemsPerPageOptions.forEach(value =>
            params.append("itemsPerPageOptions", value.toString()),
        );
        if (pagination.sort) {
            params.append("sort", pagination.sort);
        }
        params.append(
            "sortDirection",
            pagination.sortDirection.valueOf().toString(),
        );
        return params;
    }, [
        extraSearchParams,
        pagination.itemsPerPage,
        pagination.itemsPerPageOptions,
        pagination.page,
        pagination.sort,
        pagination.sortDirection,
    ]);

    const response = useSWR<PagedResults<TModel>, TError>(
        path + "?" + params.toString(),
    );

    const setPage = useCallback(
        (page: number) =>
            setPagination(prev => {
                if (prev.page === page + 1) {
                    return prev;
                }
                return { ...prev, page: page + 1 };
            }),
        [],
    );

    const setItemsPerPage = useCallback(
        (items: number) =>
            setPagination(prev => {
                if (prev.itemsPerPage === items) {
                    return prev;
                }
                return { ...prev, itemsPerPage: items };
            }),
        [],
    );

    const setSort = useCallback(
        (field: string | undefined, sortDirection: SortDirection) =>
            setPagination(prev => {
                if (
                    prev.sort === field &&
                    prev.sortDirection === sortDirection
                ) {
                    return prev;
                }
                return {
                    ...prev,
                    sort: field,
                    sortDirection: sortDirection,
                };
            }),
        [],
    );

    return useMemo(
        () => ({
            response,
            paginationControls: {
                setPage,
                setItemsPerPage,
                setSort,
            },
        }),
        [response, setItemsPerPage, setPage, setSort],
    );
}
