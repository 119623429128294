import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

interface ResponseData {
    customerId: number | undefined;
}

export function CustomerViewErrorRedirect() {
    const navigate = useNavigate();
    useEffect(() => {
        navigate("/app/customers");
    }, [navigate]);
    return <></>;
}

export function useCustomerIdFilter(): ResponseData {
    const { customerId } = useParams();

    let customerIdParsed: number | undefined = undefined;
    if (customerId) {
        customerIdParsed = parseInt(customerId);
        if (isNaN(customerIdParsed)) {
            customerIdParsed = undefined;
        }
    }

    return {
        customerId: customerIdParsed,
    };
}
