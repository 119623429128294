import { BareFetcher } from "swr";
import { Environment } from "../env";
import { Error } from "../models/error/Error";
import { useCallback } from "react";
import { useOidcFetch } from "@axa-fr/react-oidc";
import { useTranslation } from "react-i18next";


// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Any = any;

type Options = RequestInit;

interface RequestResult<TOut = Any> {
    status: number;
    json: () => Promise<TOut>;
    blob: () => Promise<Blob>;
}

type HandleGet = <TOut = Any>(
    path: string,
    options?: Options,
) => Promise<RequestResult<TOut>>;
type HandlePost = <TIn = Any, TOut = Any>(
    path: string,
    body: TIn,
    options?: Options,
) => Promise<RequestResult<TOut>>;
type HandlePostForm = <TOut = Any>(
    path: string,
    form: FormData,
    multipart?: boolean,
    options?: Options,
) => Promise<RequestResult<TOut>>;
type HandlePut = <TIn = Any, TOut = Any>(
    path: string,
    body: TIn,
    options?: Options,
) => Promise<RequestResult<TOut>>;
type HandleDelete = <TOut = Any>(
    path: string,
    options?: Options,
) => Promise<RequestResult<TOut>>;

interface UseFetchResult {
    raw: typeof window.fetch;
    get: HandleGet;
    post: HandlePost;
    postForm: HandlePostForm;
    put: HandlePut;
    delete: HandleDelete;
}

function createRequestUri(path: string) {
    return `${Environment.API_HOST}/api/${path}`;
}

function createRequestData(
    method: string,
    body: Any | undefined,
    options: RequestInit,
    extraParams: RequestInit | undefined,
) {
    const data: RequestInit = {
        ...options,
        method,
        headers: {
            "Content-Type": "application/json",
            ...options.headers,
        },
        body: handleBody(body),
    };
    if (extraParams) {
        return Object.assign(data, extraParams);
    }
    return data;
}

function handleBody(body: Any): BodyInit | undefined {
    if (!body) {
        return undefined;
    }
    return JSON.stringify(body);
}

export function useFetch(): UseFetchResult {
    const { fetch } = useOidcFetch(window.fetch);

    const fetchGet = useCallback<HandleGet>(
        (path, options) =>
            fetch(
                createRequestUri(path),
                createRequestData("GET", undefined, {}, options),
            ),
        [fetch],
    );

    const fetchPost = useCallback<HandlePost>(
        async (path, body, options) =>
            fetch(
                createRequestUri(path),
                createRequestData("POST", body, {}, options),
            ),
        [fetch],
    );

    const fetchPostForm = useCallback<HandlePostForm>(
        async (path, body, multipart = false, options) => {
            const headers: Record<string, string> = {
                ...(options?.headers as Record<string, string>),
                "Content-Type": "application/x-www-form-urlencoded",
            };

            if (multipart) {
                // https://stackoverflow.com/questions/39280438/fetch-missing-boundary-in-multipart-form-data-post
                delete headers["Content-Type"];
            }

            return await fetch(createRequestUri(path), {
                ...options,
                method: "POST",
                headers,
                body,
            });
        },
        [fetch],
    );

    const fetchPut = useCallback<HandlePut>(
        async (path, body, options) =>
            fetch(
                createRequestUri(path),
                createRequestData("PUT", body, {}, options),
            ),
        [fetch],
    );

    const fetchDelete = useCallback<HandleDelete>(
        async (path, options) =>
            fetch(
                createRequestUri(path),
                createRequestData("DELETE", undefined, {}, options),
            ),
        [fetch],
    );

    return {
        raw: fetch,
        get: fetchGet,
        post: fetchPost,
        postForm: fetchPostForm,
        put: fetchPut,
        delete: fetchDelete,
    };
}

export function useSWRFetcher(requestOptions?: Options) {
    const { t } = useTranslation();
    const fetch = useFetch();

    const isErrorResponse = useCallback(
        (status: number, data: Any | Error): data is Error => {
            return status < 200 || status >= 299;
        },
        [],
    );

    const fetcher = useCallback<BareFetcher<Any>>(
        async resource => {
            const result = await fetch.get(resource, requestOptions);
            const data = await result.json();

            if (isErrorResponse(result.status, data)) {
                console.error(
                    `Error occured | Code: ${data.code}: ${data.message}`,
                );
                throw t("errors.codes." + data.code);
            }

            return data;
        },
        [fetch, isErrorResponse, requestOptions, t],
    );

    return {
        fetcher,
    };
}
