import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";

interface ConfirmButtonDialogProps {
    open: boolean;
    text: string;
    isDanger: boolean;
    onClose: (confirmed: boolean) => void;
}

export function ConfirmButtonDialog({ open, text, isDanger, onClose }: ConfirmButtonDialogProps) {
    const { t } = useTranslation();

    return (
        <Dialog open={open} onClose={() => onClose(false)}>
            <DialogTitle>{t("common.warning")}</DialogTitle>
            <DialogContent>
                {text ?? t("common.proceed_caution")}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color={isDanger ? "primary" : "error"} onClick={() => onClose(false)}>{t("common.no")}</Button>
                <Button variant="contained" color={isDanger ? "error" : "primary"} onClick={() => onClose(true)}>{t("common.yes")}</Button>
            </DialogActions>
        </Dialog>
    )
}